import React, { useRef, useContext, useMemo, useEffect, useState } from 'react';
import { UserContext } from '../../../../context/provider/UserProvider';
import { AuthFetch } from '../../../../services/api';
import { notify } from '../../../../utils/utils';


export const MapaAlertas = ({ googleMap }) => {
    const { stateUser } = useContext(UserContext);
    const mapDiv = useRef();
    const urlBaseEntidades = process.env.REACT_APP_PLAMIN_API + '/api/Entities'

    const [poligonoTest,setPoligonoTest] = useState('')

    const obtenerPoligono = async () => {
        const response = await AuthFetch({
            url: urlBaseEntidades + '/jurisdictionByEntity?' + new URLSearchParams({
                CodEntidad: stateUser.codEntidad || 0
            })
        });
        
        if(response.isValid){
            setPoligonoTest(response.content[0].poligonoJurisdiccion)
        }else{
            notify(response.content, 'error');
        }
    }

    const mostrarZona = (listLatLng) => {
        if (googleMap && googleMap.mapDiv !== '') {
            googleMap.quitarTodosLosPoligonos();

            // listLatLng.split('|').forEach((puntosDivididos, index) => {
                if (listLatLng !== '') {
                    const completelistLatLng = listLatLng.split(',').map(latLng => {
                        const [ lat, lng ] = latLng.split(';');
                        return { lat: Number(lat), lng: Number(lng) };
                    })

                    googleMap.crearPoligono({
                        id: `poligono-zona-0`,
                        listLatLng: completelistLatLng,
                        color: '#FF0000',
                        opacidadFondo: 0.1,
                    })

                    googleMap.updateCenterAndZoom(completelistLatLng)
                }
            // })
        }
    }

    useEffect(() => {
        obtenerPoligono()
    },[])

    useEffect(() => {
        if (googleMap) {
            googleMap.inicializarMapa(mapDiv.current);
        }
    }, [googleMap]);

    useEffect(() => {
        if (googleMap) {
            mostrarZona(poligonoTest)
        }
    }, [poligonoTest, googleMap])

    return (
        <div ref={mapDiv} className='w-full h-full'></div>
    )
}