import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthFetch } from '../../../services/api';
import { UserContext } from '../../../context/provider/UserProvider';
import { useHistory } from "react-router-dom";
import { SocketContext } from '../../../context/provider/SocketProvider'
import { Timer } from './Timer';
import { Slideshow, Slide } from '../../components/utils/SliderShow';
import logoAbexa from '../../../assets/images/LogoAbexa-Web.png'
import logoPlamin from '../../../assets/images/PlaminLogo-Web.png'
import { CambiarClave } from '../../components/forms/CambiarClave';
import { Modal } from '../../components/modal/Modal';
import { useModal } from '../../../hooks/useModal';

export const Header = ({openMenu}) => {
    const [openCambioClave,setOpenCambioClave] = useState(false)
    const dropDown = useRef()
    const { stateUser, signOut } = useContext(UserContext)
    const { mqttDisconnect } = useContext(SocketContext)
    const history = useHistory();
    const [ isOpenCierreSesion, openModalCierreSession, closeModalCierreSesion ] = useModal()
        



    const singOutSession = () => {        
        cerrarSesion()
        // mqttDisconnect();
        // signOut()
        // history.push('/')


    }

    const toggleUser = () => {
        const isClosed = dropDown.current?.classList?.contains('hidden')
        isClosed && dropDown.current?.classList?.remove('hidden')
        !isClosed && dropDown.current?.classList?.add('hidden')
    }

    const cerrarSesion= async () => {
        // return
        // window.location.href = `https://wso2is-service.abexa.pe/oidc/logout?id_token_hint=${stateUser.id_token}&post_logout_redirect_uri=http://192.168.1.96:3001/PantallaCarga&state=logout_success`;
        window.location.href = `https://wso2is-service.abexa.pe/oidc/logout?id_token_hint=${stateUser.id_token}&post_logout_redirect_uri=https://www.plaminoficial.com/PantallaCarga&state=logout_success`;
        //  const response = await AuthFetch({url:'https://wso2is-service.abexa.pe/oidc/logout'})
        //const response = await AuthFetch({url:'http://192.168.1.96:3001/CierreSesion'})
    }

    
    const fotoUrl = stateUser.urlFoto || 'https://minio.api.abexacloud.com/api/Archivo/verImagen?NombreCarpeta=photo-users-plamin&NombreImagen=11111111'
    // document.addEventListener('mouseup',function(e){
    //     if(!dropDown.current?.contains(e.target)){
    //         if(!dropDown.current?.classList?.contains('hidden')){
    //             toggleUser()
    //         }
    //     }
    // })

    return (
        <>
            <div className="header">
                <nav>
                    <div className="width-full px-4 lg:px-2">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="items-center hidden lg:flex absolute z-50 w-[110px]">
                                <button onClick={openMenu} type="button" className=" inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                                    <i className="fas fa-bars text-[20px]"></i>
                                </button>
                            </div>
                            <div className="flex-1 flex items-stretch justify-start lg:items-center lg:justify-center lg:w-full lg:absolute max-h-full">
                                <div className="flex-shrink-0 flex justify-center items-center w-[250px] lg:w-[180px]" >
                                    <main className=''> 
                                        <Slideshow  controles={false} autoplay={true} velocidad="1500" intervalo="4000">
                                            <Slide className='flex justify-center items-center'>
                                                <a href=""> <img  src={logoAbexa} className='lg:w-[200px] lg:h-[50px] w-[240px] h-[65px]' alt=""/> </a>
                                            </Slide>
                                            <Slide className='flex justify-center items-center'>
                                                <a href=""> <img src={logoPlamin} className='lg:w-[200px] lg:h-[50px] w-[240px] h-[65px]' alt=""/> </a>
                                            </Slide>
                                        </Slideshow>
                                    </main>
                                </div>
                            </div>

                            <div className="  lg:relative w-[350px]  h-[100%] flex items-center lg:right-0 inset-auto ml-6">
                                <img className='lg:hidden rounded-full  top-1 h-[60px]  w-[60px] absolute right-0' onClick={()=>toggleUser()} src={fotoUrl} alt=''/>
                                <Timer></Timer>
                                <div className=' absolute top-9 right-[150px] lg:right-4 lg:top-5'>
                                    <span className="text-[14px] lg:hidden"><i className='fa fa-thumb-tack'/> {stateUser.nomEntidadPadre || '- '} </span>
                                    <span className='lg:hidden'>|</span>
                                    <span className="text-[14px] lg:hidden"> {stateUser.nomEntidad || 'Sin Entidad Asignada'} </span>
                                    <span className='lg:hidden'>|</span>
                                    <button onClick={() => toggleUser()} className="lg:right-0 absolute bottom-0 lg:bottom-auto lg:top-3 px-1 py-1 hover:bg-blue-600 rounded">
                                        <div className="text-[14px]">{ stateUser.nomUsuario }</div>
                                    </button>
                                </div>

                                    <div ref={dropDown} className="absolute top-[66px] right-0 hidden z-50 w-44 bg-white rounded divide-y divide-blue-100 shadow dark:bg-blue-800">
                                        <ul className="py-1 text-sm text-white-700 dark:text-white-900" aria-labelledby="dropdownButton">
                                            <li><a onClick={() => setOpenCambioClave(!openCambioClave)} className="block py-2 px-4 hover:bg-blue-100 dark:hover:bg-blue-600 dark:hover:text-white">Cambiar Clave</a></li>
                                        </ul>
                                        <ul className="py-1 text-sm text-white-700 dark:text-white-900" aria-labelledby="dropdownButton">
                                            {/* <li><a onClick={() => {cerrarSesion();singOutSession()}} className="block py-2 px-4 hover:bg-blue-100 dark:hover:bg-blue-600 dark:hover:text-white">Cerrar Sesión</a></li> */}
                                            <li><a onClick={() => {singOutSession()}} className="block py-2 px-4 hover:bg-blue-100 dark:hover:bg-blue-600 dark:hover:text-white">Cerrar Sesión</a></li>
                                        </ul>
                                    </div> 
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <CambiarClave abrirModal={openCambioClave}></CambiarClave>
            <Modal
                isOpen={isOpenCierreSesion}
                closeModal={closeModalCierreSesion}
                title={'Cerrar sesión'}
                validButton={{confirm:false, denied:true}}
            >
                {/* <iframe src='https://wso2is-service.abexa.pe/oidc/logout' width={600} height={400} title='Cerrar sesión'></iframe> */}
            </Modal>
        </>
    )
        
}