import React, { useEffect, useState } from "react";
import { ContenedorParametros } from "../../components/utils/ContenedorParametros";

const clasePestania =
    "border-x border-t rounded-t-md py-2 w-[90px] bg-gray-600 flex-shrink-0 text-sm flex flex-wrap justify-center";

export const ConsultasReportes = () => {
    const jsonMenus = [
        {
            nomMenu: "RP001",
            nombreReporte: "Reporte de alcaldes",
        },
        {
            nomMenu: "RP002",
            nombreReporte: "Reporte de centros poblados",
        },
        {
            nomMenu: "RP003",
            nombreReporte: "Reporte de incidencias general",
        },
        {
            nomMenu: "RP004",
            nombreReporte:
                "Reporte de incidencias por departamento,distrito y provincia",
        },
        {
            nomMenu: "RP005",
            nombreReporte: "Reporte de incidencias por tipo",
        },
        {
            nomMenu: "RP006",
            nombreReporte: "Reporte de incidencias por estado",
        },
        {
            nomMenu: "RP007",
            nombreReporte: "Reporte de centros poblados por departamento",
        },
    ];

    const [menuActual, setMenuActual] = useState("HOME");
    const [menusAbiertos, setMenusAbiertos] = useState([]);
    const [componentes, setComponentes] = useState([]);

    useEffect(() => {
        cargarComponentes();
    }, []);

    const funcionAbrirMenu = (nombreMenu) => {
        if (!menusAbiertos.some((elem) => elem == nombreMenu)) {
            setMenuActual(nombreMenu);
            setMenusAbiertos((arrAnterior) => {
                return [...arrAnterior, nombreMenu];
            });
        }
    };

    const cargarComponentes = async () => {
        const nuevosComponentes = await Promise.all(
            jsonMenus.map(async (data) => {
                try {
                    const modulo = await import(
                        `./VistaReportes/${data.nomMenu}`
                    );
                    return {
                        nomMenu: data.nomMenu,
                        nomReporte: data.nombreReporte,
                        componente: modulo.default,
                        error: null, // No hay error
                    };
                } catch (error) {
                    // CUANDO NO TRAE NADA EL IMPORT
                    console.error(`Error importing ${data.nomMenu}:`, error);
                    return {
                        nomMenu: data.nomMenu,
                        nomReporte: data.nombreReporte,
                        componente: null,
                        error: error.message, // Mensaje de error
                    };
                }
            })
        );
        setComponentes(nuevosComponentes);
    };

    return (
        <>
            <div className="overflow-auto flex-grow flex flex-col mt-2">
                <div className="flex gap-1">
                    <button
                        className={`${clasePestania} ${
                            menuActual == "HOME" ? "!bg-blue-600" : ""
                        }`}
                        onClick={() => setMenuActual("HOME")}
                    >
                        HOME
                    </button>
                    {menusAbiertos.map((nombreMenu, i) => (
                        <button
                            key={i + 1}
                            className={`${clasePestania} relative ${
                                menuActual == nombreMenu ? "!bg-blue-600" : ""
                            }`}
                            onClick={() => setMenuActual(nombreMenu)}
                        >
                            <span>{nombreMenu}</span>
                            <div
                                onClick={(event) => {
                                    event.stopPropagation(); // EVITA QUE SE EJECUTE EL ONCLICK DEL BOTON
                                    setMenuActual("HOME");
                                    setMenusAbiertos((arrAnterior) => {
                                        const arrCopia = [...arrAnterior];
                                        return arrCopia.filter(
                                            (e) => e != nombreMenu
                                        );
                                    });
                                }}
                                className="absolute z-10 top-0 right-0 rounded-full w-[16px] h-[16px] flex items-center justify-center bg-red-600 hover:scale-105"
                            >
                                <i
                                    className="fa fa-times text-[10px]"
                                    aria-hidden="true"
                                ></i>
                            </div>
                        </button>
                    ))}
                </div>
                <div className="flex-grow overflow-auto border border-gray-500 p-2 flex flex-col">
                    {/* MENU HOME */}
                    <div
                        className={`${
                            menuActual == "HOME" ? "" : "hidden"
                        } w-full h-full overflow-auto containerScroll flex flex-col p-6`}
                    >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-left">Nº</th>
                                    <th className="text-left">CÓDIGO</th>
                                    <th className="text-left">TÍTULO</th>
                                    <th className="text-left">NOMBRE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jsonMenus.map((data, i) => {
                                    return (
                                        <tr
                                            key={i + 1}
                                            onClick={() =>
                                                funcionAbrirMenu(data.nomMenu)
                                            }
                                            className={`${
                                                menusAbiertos.some(
                                                    (e) => e == data.nomMenu
                                                )
                                                    ? "!bg-[#5985eb]"
                                                    : ""
                                            }`}
                                        >
                                            <td className="text-left">
                                                {i + 1}
                                            </td>
                                            <td className="text-left">
                                                {data.nomMenu}
                                            </td>
                                            <td className="text-left">
                                                {data.nombreReporte}
                                            </td>
                                            <td className="text-left">
                                                {data.nombreReporte}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* MENUS RESTANTES */}
                    {componentes.map(
                        (
                            {
                                nomMenu: nomMenu,
                                nomReporte: nombreReporte,
                                componente: ComponenteDinamico,
                            },
                            i
                        ) =>
                            menuActual == nomMenu ? (
                                <div
                                    key={i + 1}
                                    className={`${
                                        menuActual == nomMenu ? "" : "hidden"
                                    } w-full h-full flex-grow flex flex-col`}
                                >
                                    <ComponenteDinamico
                                        nombreReporte={nombreReporte}
                                    />
                                </div>
                            ) : (
                                <React.Fragment key={i + 1}></React.Fragment>
                            )
                    )}
                </div>
            </div>
        </>
    );
};
