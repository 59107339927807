import { useState, useEffect } from 'react';
import { AuthFetch } from '../../services/api';
import { notify } from '../../utils/utils';
const urlBasePlamin = process.env.REACT_APP_PLAMIN_API + "/api";

export const useCalificacionAlerta = () => {
    const [ calificaciones, setCalificaciones ] = useState([]);

    useEffect(() => {
        listarCalificacion();
    }, []);

    const listarCalificacion = async () => {
        const response = await AuthFetch({
            url: urlBasePlamin + '/Alerts/ratings'
        });

        if (response.isValid) {
            setCalificaciones(response.content);
        } else  {
            notify(response.content , "error")    
        }
    }



    



    return { calificaciones };
}